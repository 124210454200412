import HttpService from '@/common/axios.http.service';
import { SHOW_MESSAGE } from '@/store/modules/snackBar.module';

const ApiService = {
	init(store) {
		this.store = store;
		HttpService.errorHandler = this.errorHandler;
		HttpService.customHeaders = this.setCustomHeaders;
		HttpService.init(store);
	},

	setCustomHeaders() {
		return [
			{ key: 'clisrc', value: process.env.VUE_APP_TITLE },
			{ key: 'cliver', value: this.$store.getters.appVersion },
		];
	},

	errorHandler(result) {
		let err;
		if (result.response.data) {
			err = result.response.data;
			if (err.responseCode === 600 || err.responseCode === 601) {
				this.$store.dispatch(SHOW_MESSAGE, { color: 'red', message: err.responseText });
				setTimeout(() => {
					this.$store.dispatch('logout');
				}, 500);
			} else if (err.responseCode === 6003) {
				setTimeout(() => {
					this.$store.dispatch('logout');
				}, 500);
			} else {
				this.$store.dispatch(SHOW_MESSAGE, { color: 'red', message: err.responseText });
			}
		} else {
			err = { responseText: result.message };
			this.$store.dispatch(SHOW_MESSAGE, { color: 'red', message: err.responseText });
		}

		return Promise.reject(err);
	},

	post(url, payload, config) {
		return new Promise((resolve, reject) => {
			HttpService.post(url, payload, config)
				.then((response) => {
					resolve(response);
				})
				.catch((error) => {
					reject(error);
				});
		});
	},
};

export default ApiService;
